import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { getCurrentProfile } from "../../actions/profile";
import { getBookings } from "../../actions/booking";
import { getMessages } from "../../actions/message";
import { getLikedCompanies } from "../../actions/company";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
//import DashboardActions from './DashboardActions';
import Spinner from "../layout/Spinner";
import BookingItem from "../booking/BookingItem";
import Logo from "../../images/Cake-Slice.png";
import Cal from "../../asssets/icons/whitecalendar.svg";
import UserIcon from "../../images/user-icon.png";
import Pagination from "react-bootstrap/Pagination";
import { getCurrentCompanyList } from "../../actions/company";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment";
import { Button } from "bootstrap";
import api from "../../utils/api";
import WaitlistModal from "../waitlist/WaitlistModal";
import { showModal, hideModal, setCurrentCompany } from '../../actions/waitlistModal';


const Dashboard = ({
  getCurrentProfile,
  getBookings,
  getMessages,
  getLikedCompanies,
  auth: { user },
  profile: { profile, loading },
  booking: { bookings },
  message: { messages, loading_messages },
  company: { liked },
  companyList,
  totalUserCompanies,
  showModal, hideModal, setCurrentCompany, waitlistModal,
  getCurrentCompanyList,
}) => {
  const [loadingDynamic, setloadingDynamic] = useState(false);
  const [currentPage, setcurrentPage] = useState(0);
  const [waitlists, setWaitlists] = useState([]); // Add state for waitlists
  var totalAdminBusiness = totalUserCompanies;
  const [currentWaitlistCompany, setCurrentWaitlistCompany] = useState(null);

  const dispatch = useDispatch();
  

  useEffect(() => {
    const fetchWaitlists = async () => {
      try {
        // Fetch waitlist data
        const waitlistResponse = await api.get(`/waitlist/user/${user?._id}`);
        const waitlistData = waitlistResponse.data;

        // Extract unique company IDs
        const uniqueCompanyIds = [
          ...new Set(waitlistData.map((waitlist) => waitlist.company))
        ];

        // Fetch company details for each unique ID
        const companyPromises = uniqueCompanyIds.map((id) =>
          api.get(`/company/${id}`)
        );
        const companyResponses = await Promise.all(companyPromises);

        // Map company details by ID
        const companyMap = {};
        companyResponses.forEach((response) => {
          companyMap[response.data._id] = response.data;
        });

        // Merge company details into waitlist data
        const enrichedWaitlistData = waitlistData.map((waitlist) => ({
          ...waitlist,
          companyDetails: companyMap[waitlist.company]
        }));

        console.log("Enriched waitlist data:", enrichedWaitlistData);

        setWaitlists(enrichedWaitlistData);
      } catch (error) {
        console.error("Error fetching waitlist data:", error);
        // Handle the error appropriately
      }
    };

    if (user) {
      fetchWaitlists();
    }
  }, [user]);


  useEffect(() => {
    getCurrentProfile();
    getBookings();
    getMessages();
    getLikedCompanies();
  }, [getBookings, getMessages, getLikedCompanies, getCurrentProfile]);

  // Businessess List
  useEffect(() => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    getCurrentCompanyList(currentPage, "");
  }, [currentPage]);

  const handleCloseModal = () => {
    hideModal();
  };

  const handleOpenModal = (company) => {
    console.log("opening modal with company", company);
    dispatch(setCurrentCompany(company));
    dispatch(showModal());
  };

  const onPrev = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage - 1);
  };

  const pageNoClick = (i) => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(i);
  };

  const onNext = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage + 1);
  };

  const [isTourOpen, setisTourOpen] = useState(false);
  const closeTour = () => {
    setisTourOpen(false);
  };
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const history = useHistory();
  // bookings = mockBookings;
  return loading && profile === null ? (
    <Spinner />
  ) : (
    <div className="max-1200 mx-auto mt-md-5 mt-0 pt-5 pt-md-5">
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you're looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div className="row header-bg" data-tut="reactour__iso">
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
        />

        <div className="mobile-dashboard-header">
          <a
            onClick={() => {
              history.push("/account");
            }}
            className="user-icon"
          >
            <img src={UserIcon} alt="User Icon" />
          </a>
          <div className="dashborad-logo">
            <img src={Logo} alt="Appointment Cake" className="site-logo" />
          </div>
        </div>

        <div className="dashboard-header d-flex flex-column justify-content-center w-100">
          <p className="text-2m w-fit font-medium mb-0">
            {moment().format("dddd, MMMM D")}
          </p>
          <p className="text-lg w-fit font-weight-bold mb-0">
            Hi, {user && user.firstName} {user && user.lastName}
          </p>
          <a
            onClick={() => history.push("/account")}
            className="user-icon desktop-show btn btn-info max_width d-none"
          >
            <i className="fa-solid fa-user"></i> User
          </a>
          <button
            id="change-log"
            type="button"
            onClick={() => setisTourOpen(true)}
            className="btn btn-info max_width d-none"
          >
            <i className="fas fa-edit"></i> <span> Guide </span>{" "}
          </button>
        </div>
      </div>
      <div className="upcoming-table p-4 p-md-0">
        <p className="text-2m font-semibold my-4">Get Started</p>
        <div className="dashboard-left">
        <div className="row  widget-col">
          <div className="widget col-md-6 mb-5 px-2" data-tut="upcomming_appt">
            <div className="border-8 border-grey mb-4 mb-md-0 ">
              <p className="text-center text-2m bg-orange text-white px-3 card-head font-semibold m-0">
                Upcoming Appointments
              </p>
              <div className="p-3 maxheight-400-auto">
                <table className="table buisness-table dashboard-table">
                  <tbody>
                    {Array.isArray(bookings) && bookings?.length > 0 ? (
                      bookings.map((booking) => (
                        <BookingItem key={booking?._id} booking={booking} />
                      ))
                    ) : (
                      <tr>
                        <td className="p-0">
                          <p className="text-sm font-medium">
                            You have no upcoming appointments
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* wiatlist */}
            <div id="dashboard-waitlist" className="mt-3 mb-3">
              <div className="border-8 border-grey">
                <p className="text-center text-2m bg-red text-white px-3 card-head font-semibold m-0">
                  Your Waitlists
                </p>
                <div className="px-3 maxheight-400-auto">
                  <table className="table buisness-table dashborad-your-buisness">
                    <tbody className="d-flex flex-column">
                      {waitlists && waitlists.length > 0 ? (
                        [...new Map(
                          waitlists.map((waitlist) => [waitlist.companyDetails._id, waitlist])
                        ).values()].map((waitlist) => (
                          <tr
                            className="border-b-grey-2 d-flex justify-content-between align-items-md-center py-4 flex-column flex-md-row"
                            key={waitlist.companyDetails._id}
                          >
                            <div className="mb-3 mb-md-0">
                              <p className="font-semibold text-sm m-0">
                                {waitlist.companyDetails.name}
                              </p>
                              <p className="text-sm m-0">
                                {waitlist.companyDetails.location.street_address} {" "}
                              </p>
                              <p className="text-sm m-0">
                                {waitlist.companyDetails.phone}
                              </p>
                            </div>
                            <td className="p-0">
                              <button onClick={()=>handleOpenModal(waitlist.companyDetails)} className="btn-light-orange text-sm font-semibold text-orange py-2 px-4">
                                Edit
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="p-3">
                            <p className="text-sm font-medium">No waitlists found</p>
                          </td>
                        </tr>
                      )}
                      <button
                        className="btn-red-2 text-white py-2 mx-auto mb-0 mt-3"
                        onClick={() => history.push(`/account#waitlist-manager`)}
                      >
                        <img src={Cal} alt="calendar" /> Waitlist Manager
                      </button>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* liked businesses */}
            {liked && liked.length > 0 && (
              <div id="dashboard-liked" className="mt-3 mb-3">
                <div className="border-8 border-grey">
                  <p className="text-center text-2m bg-aqua text-white px-3 card-head font-semibold m-0">
                    Your Liked Businesses
                  </p>
                  <div className="px-3 maxheight-400-auto">
                    <table className="table buisness-table dashborad-your-buisness">
                      <tbody className="d-flex flex-column">
                        {liked.map((company, i) => (
                          <tr
                            className={`${
                              liked.length === i + 1
                                ? ""
                                : "border-b-grey-2"
                            } d-flex justify-content-between align-items-md-center py-4 flex-column flex-md-row`}
                            key={company._id}
                            id={company._id}
                          >
                            <div className="mb-3 mb-md-0">
                              <p className="font-semibold text-sm m-0">
                                {company.name}
                              </p>
                              <p className="mb-0 text-sm mt-1">
                                {company?.location?.street_address} {" "}
                              </p>
                              <p className="mb-0 text-sm">
                                {company?.phone}
                              </p>
                            </div>
                            <td className="p-0">
                              <button
                                className="btn-light-orange text-sm font-semibold text-orange py-2 px-4"
                                onClick={() =>
                                  history.push({
                                    pathname: `/company/${company._id}`,
                                    state: {
                                      companyDetails: company,
                                      settingProp: "allbsns",
                                    },
                                  })
                                }
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="widget col-md-6 margin-top px-2 px-md-0 mb-5"
            data-tut="your_business"
          >
            <div className="border-8 border-grey">
              <p className="text-center text-2m bg-aqua text-white px-3 card-head font-semibold m-0">
                Your Businesses
              </p>
              <div className="px-3 maxheight-400-auto">
                <div className="appointment-tbl">
                  <table className="table buisness-table dashborad-your-buisness">
                    <tbody className="d-flex flex-column">
                      {companyList && companyList?.length > 0 ? (
                        companyList.map((comp, i) => (
                          <tr
                            className={`${
                              companyList?.length === i + 1
                                ? ""
                                : "border-b-grey-2"
                            } d-flex justify-content-between align-items-md-center py-4 flex-column flex-md-row `}
                            key={i}
                            id={comp._id}
                          >
                            <div className="mb-3 mb-md-0">
                              <p className="font-semibold text-sm m-0">
                                {comp.name}
                              </p>
                              <p className="mb-0 text-sm mt-1">
                                {comp?.location?.street_address} {" "}
                                {/* {comp?.location?.street_address_2} {" "} {comp?.location?.city} {" "} {comp?.location?.country} {", "} */}
                                </p>
                                <p className="mb-0 text-sm">
                                 {comp?.phone}
                              </p>
                            </div>

                            <td className="p-0 ">
                              <button
                                className=" btn-light-orange text-sm font-semibold text-orange py-2 px-4"
                                onClick={() =>
                                  history.push({
                                    pathname: `/business/service/${comp._id}`,
                                    state: {
                                      companyDetails: comp,
                                      settingProp: "allbsns",
                                    },
                                  })
                                }
                              >
                                Services
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5} style={{ textAlign: "center" }}>
                            No Data Found
                          </td>
                        </tr>
                      )}
                      <button
                        className="btn-aqua text-white py-2 mx-auto mb-0"
                        onClick={() => history.push(`/admin`)}
                      >
                        <img src={Cal} alt="calendar" /> Business Manager
                      </button>
                    </tbody>
                    {/* </thead> */}
                  </table>
                </div>
              </div>
            </div>
            {companyList && companyList?.length > 0 ? (
              <Pagination style={{ marginTop: "10px", display: "none" }}>
                <Pagination.Prev
                  disabled={currentPage == 0 || companyList?.length == 0}
                  onClick={onPrev}
                  className="prev-nav"
                />
                {companyList &&
                  [...Array(totalAdminBusiness)].map((data, i) => {
                    if (i < Math.ceil(totalAdminBusiness / 10)) {
                      return (
                        <Pagination.Item
                          key={i}
                          active={currentPage == i}
                          onClick={() => pageNoClick(i)}
                        >
                          {i + 1}
                        </Pagination.Item>
                      );
                    }
                  })}
                <Pagination.Next
                  disabled={
                    currentPage == Math.ceil(totalAdminBusiness / 10) - 1 ||
                    companyList?.length == 0
                  }
                  onClick={onNext}
                  className="next-nav"
                />
              </Pagination>
            ) : (
              ""
            )}
          </div>
        </div>
        
        </div>
       
      </div>
      <WaitlistModal
        show={waitlistModal.showModal}
        onHide={handleCloseModal}
        currentCompany={waitlistModal.currentCompany}
      />
    </div>
  );
};

const steps = [
  {
    selector: '[data-tut="reactour__iso"]',
    content: `Header section with the name of logged in person.`,
  },
  {
    selector: '[data-tut="upcomming_appt"]',
    content: `Section for upcoming appointments for the user.`,
  },
  {
    selector: '[data-tut="your_business"]',
    content: `Messages related to booked appointments and services.`,
  },
  // {
  //   selector: '[data-tut="fav_place"]',
  //   content: `Places added to favorite list.`
  // }
];

Dashboard.protoTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getBookings: PropTypes.func.isRequired,
  getLikedCompanies: PropTypes.func.isRequired,
  getMessages: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  liked: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  setCurrentCompany: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  waitlistModal: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  booking: state.booking,
  company: state.company,
  message: state.message,
  companyList: state.company.companylist,
  totalUserCompanies: state.company.totalUserCompanies,
  waitlistModal: state.waitlistModal,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getBookings,
  getLikedCompanies,
  getMessages,
  getCurrentCompanyList,
  setCurrentCompany, 
  showModal, 
  hideModal, 
})(Dashboard);
