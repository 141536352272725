import React, { Fragment, useEffect, useState } from "react";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { allBusiness, deleteAdminCompany } from "../../actions/company";
import { addFreeMonth } from "../../actions/feature";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";
import Trash from "../../asssets/icons/trash.svg";
import Search from "../../asssets/icons/SearchL.svg";

const AllBusiness = ({
  allBusiness,
  company,
  totalAdminBusiness,
  deleteAdminCompany,
  addFreeMonth,
  CT,
  loading,
  pageNumber,
}) => {
  const [isSearch, setIsSearch] = useState([]);
  const [currentPage, setcurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageNumberLimit, setPageNumberLimt] = useState(12);
  const [pageCount, setPageCount] = useState(0);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(10);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [searchString, setIsSearchString] = useState("");
  const [loadingDynamic, setloadingDynamic] = useState(false);
  const [TotalBusiness, seTotalBusiness] = useState(0);

  useEffect(() => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 2000);
    seTotalBusiness(totalAdminBusiness?.totalUserCompanies);
  }, [totalAdminBusiness]);
  useEffect(() => {
    allBusiness(currentPage, searchString);
  }, []);
  useEffect(() => {
    setPageCount(Math.ceil(TotalBusiness / itemsPerPage));
    setcurrentPage(0);
  }, [TotalBusiness]);
  const onSearch = (e) => {
    setcurrentPage(0);
    const searchWord = e.target.value;
    if (searchWord == "") {
      setIsSearch([]);
      setIsSearchString("");
    } else {
      setIsSearch([searchWord]);
      setIsSearchString(searchWord);
    }
  };
  const onSearchSubmit = () => {
    allBusiness(currentPage, searchString);
  };
  const clearSearchBar = () => {
    setIsSearch([]);
    setIsSearchString("");
    setcurrentPage(0);
    allBusiness(0, "");
  };
  const onNext = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 >= maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const onPrev = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const pageNoClick = (i) => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(i);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setcurrentPage(selectedPage);
    allBusiness(selectedPage, searchString);
  };
  const onDelete = (id) => {
    deleteAdminCompany(id);
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    allBusiness(
      currentPage === 0 ? currentPage : currentPage - 1,
      searchString
    );
  };
  const handleAddFreeMonth = async (userId) => {
    setloadingDynamic(true);
    await addFreeMonth(userId);
    setloadingDynamic(false);
  };
  useEffect(() => {
    var prevelement = document.getElementsByClassName("previous");
    var nextelement = document.getElementsByClassName("next");
    if (pageCount === 1 && prevelement.length > 0 && nextelement.length > 0) {
      prevelement[0].classList?.add("disabledli");
      nextelement[0].classList?.add("disabledli");
    } else {
      if (prevelement?.classList?.contains("disabledli")) {
        prevelement.classList.remove("disabledli");
      }
      if (nextelement?.classList?.contains("disabledli")) {
        nextelement.classList.remove("disabledli");
      }
    }
  }, [pageCount]);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="row px-3">
          <Helmet>
            <title>Appointment Cake</title>
            <meta
              name="description"
              content="Whatever or whoever you're looking for,
                   Appointment Cake has you covered."
            />
          </Helmet>
          <div className="col-md-12 widget-col tab-content" id="accountTabs">
            <div className="search-bar col-md-6 mx-auto my-4 min-w-678">
              <form action="#" onSubmit={onSearchSubmit}>
                <input
                  className="search-input form-control pl-30"
                  id="autocomplete"
                  placeholder="Search"
                  type="text"
                  value={searchString}
                  onChange={onSearch}
                ></input>
              </form>
              <div className="search-bar-clear">
                <button>
                  {isSearch.length === 0 ? (
                    ""
                  ) : (
                    <i className="fas fa-close mr-4" onClick={clearSearchBar}></i>
                  )}
                </button>
              </div>

              <button>
                <img src={Search} alt="Search" onClick={onSearchSubmit} />
              </button>
            </div>
            <div className="fade show">
              <div className=" setting-overflow">
                <div className="appointment-tbl">
                  <table
                    className="table table-striped custom_table"
                  >
                    <thead>
                      <tr>
                        <th className=" py-10 text-sm font-bold">Name</th>
                        <th className=" py-10 text-sm font-bold">
                          Contact Info
                        </th>
                        <th className=" py-10 text-sm font-bold">Website</th>
                        <th className="py-10 text-sm font-bold">Free Months</th>
                        <th className=" py-10 text-sm font-bold" >
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {company && company?.length > 0 ? (
                        company.map((comp, i) => (
                          <tr
                            className="booking-item-row"
                            key={i}
                            id={comp._id}
                          >
                            {console.log("Company data:", comp)}
                            {console.log("User data:", comp.user)}
                            {console.log("Free months:", comp?.user?.free_months)}
                            <td className="rounded-s-5 font-weight-bold text-sm py-10 text-aqua">
                              {/* <Link
                                to={{
                                  pathname: `/superadmin/business-info/${comp._id}`,
                                  state: {
                                    companyDetails: comp,
                                    id: comp._id,
                                    currentTab: CT,
                                  },
                                }}
                                className="font-weight-bold"
                              > */}
                                {comp.name}
                              {/* </Link> */}
                              <div className="text-xs text-dark font-normal">
                                {comp?.location?.street_address}
                                {comp?.location?.street_address_2}
                              </div>
                            </td>
                            <td className="">{comp.phone}</td>
                            <td className="booking-notes">
                              <a
                                style={{
                                  wordBreak: "break-word"
                                }}
                                href={comp.website}
                                target="_blank"
                              >
                                {comp.website}
                              </a>
                            </td>
                            <td className="py-10 text-sm">
                              {comp?.user?.free_months || 0}
                            </td>
                            <td className="text-right rounded-e-5">
                              {/* <Link
                                to={{
                                  pathname: `/superadmin/update/${comp._id}`,
                                  state: { companyDetails: comp }
                                }}
                                className="btn btn-primary"
                                style={{ marginBottom: "10px", width: "75px" }}
                              >
                                Edit
                              </Link>{" "} */}
                              <div className="d-flex justify-content-end align-items-center">
                                <button
                                  className="btn btn-primary btn-sm mr-2"
                                  onClick={() => handleAddFreeMonth(comp.user._id)}
                                  title="Add Free Month"
                                >
                                  <i className="fas fa-gift"></i>
                                </button>
                                <img
                                  src={Trash}
                                  alt="Trash"
                                  width={24}
                                  height={24}
                                  onClick={() => onDelete(comp._id)}
                                  style={{ cursor: 'pointer' }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5} style={{ textAlign: "center" }}>
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {company && (
        <div>
          {company?.length && !loading ? (
            <>
              <div className="App mt-4">
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  forcePage={currentPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

AllBusiness.propTypes = {
  getAllPlans: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  deleteAdminCompany: PropTypes.func.isRequired,
  addFreeMonth: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.company.companylist,
  totalAdminBusiness: state.company,
  loading: state.company.loading,
  pageNumber: state.company.currentPage,
});

export default connect(mapStateToProps, { allBusiness, deleteAdminCompany, addFreeMonth })(
  AllBusiness
);
